// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://18ca9c14640565869c5c7c93c080d737@sentry.geotec.io/4",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: "norte@1.0.0",
  environment: "norte",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

import Rails from "@rails/ujs"
import { Turbo } from '@hotwired/turbo-rails'
window.Turbo = Turbo
import * as ActiveStorage from "@rails/activestorage"
import 'channels'

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap
import 'admin-lte/dist/js/adminlte'
import '../src/components'
import '../controllers'

Rails.start()
ActiveStorage.start()

