import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Connects to data-controller="toggle-firma-itec"
export default class extends Controller {
  static values ={
    updateUrl: String,
  }

  toggle(e) {
    const checked = e.target.checked;

    patch(this.updateUrlValue, {
      body: {
        firma_itec: checked
      }
    })
  }
}
