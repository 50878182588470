import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="firma-director-epicrisis"
export default class extends Controller {
  static targets = ['director'];
  static values = {
    url: String,
    registro: String
  }

  printFirmaDm(e) {
    e.preventDefault()

    const url = `${this.urlValue}.pdf?Registro=${this.registroValue}&director=${this.directorTarget.checked ? 1 : 0}`;

    window.open(url, '_blank');
  }
}
