import { Controller } from "@hotwired/stimulus"
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

// Connects to data-controller="tagify"
export default class extends Controller {
  connect() {
    const practicas = JSON.parse(this.element.dataset['practicas'])
    const whitelist = practicas.map(practica => {
      return {
        value: `${practica.Codigo} - ${practica.Descripcion.replace(/,/g, ' ')}`,
        description: `${practica.Codigo} - ${practica.Descripcion.slice(0, 15).replace(/,/g, ' ')}...`,
        code: practica.Codigo
      }
    })

    const tagifyOptions = {
      texts: {
        duplicate: 'Ya se ha agregado esta practica',
      },
      tagTextProp: 'description',
      enforceWhitelist: true,
      whitelist: whitelist,
      dropdown: {
        enabled: 3,
        maxItems: 3,
        position: "text",
        closeOnSelect: true,
        highlightFirst: true,
        searchKeys: ["value"],
      },
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value.replace(/,/g, ' ')),
      transformTag: tag => { return `${tag.value.slice(0, 20).replace(/,/g, ' ')}...` }
    }

    this.tagifyElement = new Tagify(this.element, tagifyOptions)
  }
}
