import { Controller } from "@hotwired/stimulus"
import '@popperjs/core';
import * as tempusDominus from '@eonasdan/tempus-dominus';
import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.css';
import customDateFormat from '@eonasdan/tempus-dominus/dist/plugins/customDateFormat'
import { load, faFiveIcons } from '@eonasdan/tempus-dominus/dist/plugins/fa-five'
tempusDominus.extend(customDateFormat)
tempusDominus.extend(load);

function getYesterday() {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today;
}

function getToDay() {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  return today;
}

const datepickerConfig = {
  display: {
    components: {
      decades: false,
      year: true,
      month: true,
      date: true,
      hours: false,
      minutes: false,
      seconds: false,
    }
  },
  localization: {
    locale: 'es',
    format: 'dd/MM/yyyy'
  },
  allowInputToggle: true,
  restrictions: {
    minDate: getYesterday(),
    maxDate: getToDay()
  }
}

// Connects to data-controller="datepicker-for-evoluciones-medicas"
export default class extends Controller {
  static values = {
    readonly: Boolean
  }

  connect() {
    const td = new tempusDominus.TempusDominus(this.element, datepickerConfig)

    if (this.readonlyValue) td.disable();
  }
}
