import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="filter-user-for-enable"
export default class extends Controller {
  static values = {
    url: String
  }

  userSelected(e) {
    console.log("user seleccionado")
    const user = e.currentTarget.getAttribute('data-autocomplete-user-value')

    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        Usuario: user
      }
    })
  }
}
