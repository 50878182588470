import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js';

// Connects to data-controller="search-pacientes"
export default class extends Controller {
  static targets = ['searchInput', 'allCheckbox', 'sector', 'obraSocial', 'desde', 'hasta', 'protocoloQuirurgico', 'user', 'searchInputCirujano'];
  static values = {
    url: String
  }

  connect() {
    if (this.userTarget.value.toLowerCase() == "tyo") {
      this.setupDatePickers();
    }
  }

  setupDatePickers() {
    this.desdeTarget.addEventListener('change', this.onClickPerformSearch.bind(this));
    this.hastaTarget.addEventListener('change', this.onClickPerformSearch.bind(this));
  }

  onClickPerformSearch(event) {
    event.preventDefault();
    let sectorValue = null;
    let obraSocialValue = null;
    let desdeValue = this.desdeTarget.value || null;
    let hastaValue = this.hastaTarget.value || null

    if (this.sectorTarget.value && this.sectorTarget.value !== 'Seleccione un sector') {
      sectorValue = this.sectorTarget.value;
    }

    if (this.obraSocialTarget.value && this.obraSocialTarget.value !== 'Seleccione una obra social') {
      obraSocialValue = this.obraSocialTarget.value;
    }

    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        q: this.searchInputTarget.value || null,
        cirujano: this.searchInputCirujanoTarget.value || null,
        all: this.allCheckboxTarget.checked,
        sector: sectorValue,
        obra_social: obraSocialValue,
        desde: desdeValue,
        hasta: hastaValue,
        protocolo_quirurgico: this.protocoloQuirurgicoTarget.checked
      }
    })
  }
}
