import { Controller } from "@hotwired/stimulus"
import { isEmpty } from "lodash";

// Connects to data-controller="inplace"
export default class extends Controller {
  static targets = ['cuadroEvolucionInput', 'oximetroInput', 'capnografiaInput'];

  async connect() {
    this.cuadroEvolucionValue = this.cuadroEvolucionInputTarget.value ? await JSON.parse(this.cuadroEvolucionInputTarget.value) : {};
    this.oximetroValue = this.oximetroInputTarget.value ? await JSON.parse(this.oximetroInputTarget.value) : {};
    this.capnografiaValue = this.capnografiaInputTarget.value ? await JSON.parse(this.capnografiaInputTarget.value) : {};

    this._populateValues();
  }

  changeCuadro(e) {
    e.preventDefault()

    const data = this._dataExtractor(e);
    if (data['button'].dataset['readonly'] === 'true') return;

    const kindOfValue = this._iconSwitcher(data)


    if (kindOfValue === 'should-remove') {
      delete this.cuadroEvolucionValue[`${data['vertical']}-${data['horizontal']}`]
    } else {
      this.cuadroEvolucionValue[`${data['vertical']}-${data['horizontal']}`] = {
        type: kindOfValue,
        value: data['vertical']
      }
    }

    this.cuadroEvolucionInputTarget.value = JSON.stringify(this.cuadroEvolucionValue);
  }

  changeOximetro(e) {
    e.preventDefault();

    if (e.target.value) {
      this.oximetroValue[e.target.dataset['timePosition']] = e.target.value;
    } else {
      delete this.oximetroValue[e.target.dataset['timePosition']];
    }

    this.oximetroInputTarget.value = JSON.stringify(this.oximetroValue);
  }

  changeCapnografia(e) {
    e.preventDefault();

    if (e.target.value) {
      this.capnografiaValue[e.target.dataset['timePosition']] = e.target.value;
    } else {
      delete this.capnografiaValue[e.target.dataset['timePosition']];
    }

    this.capnografiaInputTarget.value = JSON.stringify(this.capnografiaValue);
  }

  _dataExtractor(event) {
    if (event.target.nodeName.toLowerCase() === 'button') {
      return {
        button: event.target,
        horizontal: event.target.dataset['horizontalValue'],
        vertical: event.target.dataset['verticalValue'],
        element: event.target.firstChild,
        currentClass: event.target.firstChild.classList[1]
      }
    } else {
      return {
        button: event.target.parentNode,
        horizontal: event.target.parentNode.dataset['horizontalValue'],
        vertical: event.target.parentNode.dataset['verticalValue'],
        element: event.target,
        currentClass: event.target.classList[1]
      }
    }
  }

  _iconSwitcher(data) {
    let kindOfValue;
    if (data['currentClass'] === 'fa-angle-up') {
      data['element'].classList.remove('fa-angle-up');
      data['element'].classList.add('fa-circle');
      kindOfValue = 'frecuencia_respiratoria';
    } else if (data['currentClass'] === 'fa-circle') {
      data['element'].classList.remove('fa-circle');
      data['element'].classList.add('fa-check');
      kindOfValue = 'sistolica'
    } else if (data['currentClass'] === 'fa-check') {
      data['element'].classList.remove('fa-check');
      kindOfValue = 'should-remove';
    } else {
      data['element'].classList.add('fa-angle-up');
      kindOfValue = 'diastolica'
    }

    return kindOfValue;
  }

  _populateValues() {
    this.cuadroEvolucionValue
    if (!isEmpty(this.cuadroEvolucionValue)) {
      Object.keys(this.cuadroEvolucionValue).forEach(key => {
        const value = this.cuadroEvolucionValue[key];
        const element = document.getElementById(`cuadro-${key}`).firstChild;
        if (value.type === 'frecuencia_respiratoria') {
          element.classList.add('fa-circle');
        } else if (value.type === 'sistolica') {
          element.classList.add('fa-check');
        } else if (value.type === 'diastolica') {
          element.classList.add('fa-angle-up');
        }
      });
    }

    if (!isEmpty(this.oximetroValue)) {
      Object.keys(this.oximetroValue).forEach(key => {
        const value = this.oximetroValue[key];
        const element = document.getElementById(`oximetro_${key}`);
        element.value = value;
      })
    }

    if (!isEmpty(this.capnografiaValue)) {
      Object.keys(this.capnografiaValue).forEach(key => {
        const value = this.capnografiaValue[key];
        const element = document.getElementById(`capnografia_${key}`);
        element.value = value;
      })
    }
  }
}
