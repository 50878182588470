import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
// Connects to data-controller="filter-consumos-farmacias"
export default class extends Controller {
  static targets = ["desde", "hasta"]

  static values = {
    url: String,
    registro: String
  }

  onChangeFilter(e){
    e.preventDefault()

    if (this.desdeTarget.value && this.hastaTarget.value) {
      get(this.urlValue, {
        responseKind: 'turbo-stream',
        query: {
          Registro: this.registroValue,
          desde: this.desdeTarget.value,
          hasta: this.hastaTarget.value
        }
      })
    }
  }
}
